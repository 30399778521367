import { lazy } from 'react';

const PagesRoutes = [
  {
    path: "/home",
    component: lazy(() => import("../views/pages/dashboard")),
    exact: true,
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/login",
    component: lazy(() => import("../views/apps/authentication/Login")),
    exact: true,
    layout: "BlankLayout", // Example layout, ensure you have this layout defined
    meta: {
      authRoute: true,
    },
  },
  // Uncomment and add more routes as needed
  // {
  //   path: "/register",
  //   component: lazy(() => import("../../views/apps/authentication/Register")),
  //   exact: true,
  //   layout: "BlankLayout",
  // },
];

export default PagesRoutes;
